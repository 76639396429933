import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import Home from "./Home";
import Terms from "./Terms";
import Contact from "./Contact";
import landscape from "./5.svg";

const App = () => {
  let location = useLocation();
  return (
    <div className="bg-indigo-800 relative h-screen overflow-y-auto">
      {location.pathname !== "/terms" && (
        <>
          <img
            src={landscape}
            alt="cover"
            className="absolute h-full w-full object-cover"
          />
          <div className="inset-0 bg-black opacity-25 absolute"></div>
        </>
      )}
      <Routes>
        <Route index element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <header className="absolute top-0 left-0 right-0 z-20">
        <nav className="container mx-auto px-6 md:px-12 py-4">
          <div className="md:flex justify-between items-center">
            <div className="flex justify-between items-center">
              <Link to="/" className="text-white">
                <svg
                  className="w-8 mr-2 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Capa 1"
                  viewBox="0 0 16.16 12.57"
                >
                  <defs></defs>
                  <path d="M14.02 4.77v7.8H9.33V8.8h-2.5v3.77H2.14v-7.8h11.88z"></path>
                  <path d="M16.16 5.82H0L8.08 0l8.08 5.82z"></path>
                </svg>
              </Link>
              <div className="md:hidden">
                <Link to="/" className="text-white focus:outline-none">
                  <svg
                    className="h-12 w-12"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 6H20M4 12H20M4 18H20"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div className="hidden md:flex items-center">
              <Link
                to="/terms"
                className="text-lg uppercase mx-3 text-white cursor-pointer hover:text-gray-300"
              >
                Terms of Service
              </Link>
              <Link
                to="/contact"
                className="text-lg uppercase mx-3 text-white cursor-pointer hover:text-gray-300"
              >
                Contact us
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <Outlet />
    </div>
  );
};

export default App;
