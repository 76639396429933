const Terms = () => {
  return (
    <div className="px-6 md:px-12 z-10 flex items-center py-32 xl:py-40">
      <div className="bg-indigo-800 flex flex-col items-start relative z-10 text-white w-3/4 mx-auto">
        <span>Last updated 24.11.2022</span>
        <div className="text-3xl text-indigo-200 my-2">Agreement to Terms</div>
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you”) and Harun,
        doing business as Celia Bot ("Celia Bot", “we”, “us”, or “our”),
        concerning your access to and use of the Celia Bot as well as any other
        media form, website, media channel, mobile website or mobile application
        related, linked, or otherwise connected thereto (collectively, the
        “Bot”). You agree that by accessing the Bot, you have read, understood,
        and agree to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
        WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
        USING THE BOT AND YOU MUST DISCONTINUE USE IMMEDIATELY. Supplemental
        terms and conditions or documents that may be posted on the website from
        time to time are hereby expressly incorporated herein by reference. We
        reserve the right, in our sole discretion, to make changes or
        modifications to these Terms of Use from time to time. We will alert you
        about any changes by updating the “Last updated” date of these Terms of
        Use, and you waive any right to receive specific notice of each such
        change. Please ensure that you check the applicable Terms every time you
        use our Bot so that you understand which Terms apply. You will be
        subject to, and will be deemed to have been made aware of and to have
        accepted, the changes in any revised Terms of Use by your continued use
        of the Bot after the date such revised Terms of Use are posted. The
        information provided on the Bot is not intended for distribution to or
        use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access
        the Bot from other locations do so on their own initiative and are
        solely responsible for compliance with local laws, if and to the extent
        local laws are applicable. The Bot is not tailored to comply with
        industry-specific regulations (Health Insurance Portability and
        Accountability Act (HIPAA), Federal Information Security Management Act
        (FISMA), etc.), so if your interactions would be subjected to such laws,
        you may not use this Bot. You may not use the Bot in a way that would
        violate the Gramm-Leach-Bliley Act (GLBA). The Bot is intended for users
        who are at least 13 years of age. All users who are minors in the
        jurisdiction in which they reside (generally under the age of 18) must
        have the permission of, and be directly supervised by, their parent or
        guardian to use the Bot. If you are a minor, you must have your parent
        or guardian read and agree to these Terms of Use prior to you using the
        Bot.
        <div className="text-3xl text-indigo-200 my-2">
          Intellectual Property Rights
        </div>{" "}
        Unless otherwise indicated, the Bot is our proprietary property and all
        source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics on the Bot (collectively, the
        “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws of the United
        States, international copyright laws, and international conventions. The
        Content and the Marks are provided on the Bot “AS IS” for your
        information and personal use only. Except as expressly provided in these
        Terms of Use, no part of the Bot and no Content or Marks may be copied,
        reproduced, aggregated, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, distributed, sold,
        licensed, or otherwise exploited for any commercial purpose whatsoever,
        without our express prior written permission. Provided that you are
        eligible to use the Bot, you are granted a limited license to access and
        use the Bot and to download or print a copy of any portion of the
        Content to which you have properly gained access solely for your
        personal, non-commercial use. We reserve all rights not expressly
        granted to you in and to the Bot, the Content and the Marks.
        <div className="text-3xl text-indigo-200 my-2">
          User Representations
        </div>
        By using the Bot, you represent and warrant that: (1) you have the legal
        capacity and you agree to comply with these Terms of Use; (2) you are
        not under the age of 13; (3) you are not a minor in the jurisdiction in
        which you reside, or if a minor, you have received parental permission
        to use the Bot; (4) you will not access the Discord Bot through
        automated or non-human means, whether through a bot, script or
        otherwise; (5) you will not use the Bot for any illegal or unauthorized
        purpose; and (6) your use of the Bot will not violate any applicable law
        or regulation. If you provide any information that is untrue,
        inaccurate, not current, or incomplete, we have the right to suspend or
        terminate your account and refuse any and all current or future use of
        the Bot (or any portion thereof).
        <div className="text-3xl text-indigo-200 my-2">
          Prohibited Activities
        </div>
        You may not access or use the Bot for any purpose other than that for
        which we make the Bot available. The Bot may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us. As a user of the Bot, you agree not to:
        Systematically retrieve data or other content from the Bot to create or
        compile, directly or indirectly, a collection, compilation, database, or
        directory without written permission from us. Trick, defraud, or mislead
        us and other users, especially in any attempt to learn sensitive account
        information such as user passwords. Circumvent, disable, or otherwise
        interfere with security-related features of the Bot, including features
        that prevent or restrict the use or copying of any Content or enforce
        limitations on the use of the Bot and/or the Content contained therein.
        Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Bot
        Use any information obtained from the Bot in order to harass, abuse, or
        harm another person. Make improper use of our support services or submit
        false reports of abuse or misconduct. Use the Bot in a manner
        inconsistent with any applicable laws or regulations. Engage in
        unauthorized framing of or linking to the website. Upload or transmit
        (or attempt to upload or to transmit) viruses, Trojan horses, or other
        material, including excessive use of capital letters and spamming
        (continuous posting of repetitive text), that interferes with any
        party’s uninterrupted use and enjoyment of the Bot or modifies, impairs,
        disrupts, alters, or interferes with the use, features, functions,
        operation, or maintenance of the Bot. Engage in any automated use of the
        system, such as using scripts to send comments or messages, or using any
        data mining, robots, or similar data gathering and extraction tools.
        Delete the copyright or other proprietary rights notice from any
        Content. Attempt to impersonate another user or person or use the
        username of another user. Upload or transmit (or attempt to upload or to
        transmit) any material that acts as a passive or active information
        collection or transmission mechanism, including without limitation,
        clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
        cookies, or other similar devices (sometimes referred to as “spyware” or
        “passive collection mechanisms” or “pcms”). Interfere with, disrupt, or
        create an undue burden on the Bot or the networks or services connected
        to the Bot. Harass, annoy, intimidate, or threaten any of our employees
        or agents engaged in providing any portion of the Bot to you. Attempt to
        bypass any measures of the Bot designed to prevent or restrict access to
        the Bot, or any portion of the Bot. Copy or adapt the Bot’s software,
        including but not limited to Flash, PHP, HTML, JavaScript, or other
        code. Except as permitted by applicable law, decipher, decompile,
        disassemble, or reverse engineer any of the software comprising or in
        any way making up a part of the Bot. Except as may be the result of
        standard search engine or Internet browser usage, use, launch, develop,
        or distribute any automated system, including without limitation, any
        spider, robot, cheat utility, scraper, or offline reader that accesses
        the website, or using or launching any unauthorized script or other
        software. Use a buying agent or purchasing agent to make purchases on
        the website. Make any unauthorized use of the Bot, including collecting
        usernames and/or email addresses of users by electronic or other means
        for the purpose of sending unsolicited email, or creating user accounts
        by automated means or under false pretenses. Use the Bot as part of any
        effort to compete with us or otherwise use the Bot and/or the Content
        for any revenue-generating endeavor or commercial enterprise. Exchange
        virtual currency for real value in any way. Initiate illegitimate
        chargebacks. Use any type of unauthorized automation within Discord. Use
        the bot for unauthorized advertisements.
        <div className="text-3xl text-indigo-200 my-2">
          Contribution License
        </div>
        You and the Bot agree that we may access, store, process, and use any
        information and personal data that you provide following the terms of
        the Privacy Policy and your choices (including settings). By submitting
        suggestions or other feedback regarding the Bot, you agree that we can
        use and share such feedback for any purpose without compensation to you.
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Bot. You are solely
        responsible for your Contributions to the Bot and you expressly agree to
        exonerate us from any and all responsibility and to refrain from any
        legal action against us regarding your Contributions.
        <div className="text-3xl text-indigo-200 my-2">Submissions</div>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback, or other information regarding the Bot ("Submissions")
        provided by you to us are non-confidential and shall become our sole
        property. We shall own exclusive rights, including all intellectual
        property rights, and shall be entitled to the unrestricted use and
        dissemination of these Submissions for any lawful purpose, commercial or
        otherwise, without acknowledgment or compensation to you. You hereby
        waive all moral rights to any such Submissions, and you hereby warrant
        that any such Submissions are original with you or that you have the
        right to submit such Submissions. You agree there shall be no recourse
        against us for any alleged or actual infringement or misappropriation of
        any proprietary right in your Submissions.
        <div className="text-3xl text-indigo-200 my-2">
          Third-Party Websites and Content
        </div>
        The Bot may contain (or you may be sent via the Bot) links to other
        websites ("Third-Party Websites") as well as articles, photographs,
        text, graphics, pictures, designs, music, sound, video, information,
        applications, software, and other content or items belonging to or
        originating from third parties ("Third-Party Content"). Such Third-Party
        Websites and Third-Party Content are not investigated, monitored, or
        checked for accuracy, appropriateness, or completeness by us, and we are
        not responsible for any Third-Party Websites accessed through the Bot or
        any Third-Party Content posted on, available through, or installed from
        the Bot, including the content, accuracy, offensiveness, opinions,
        reliability, privacy practices, or other policies of or contained in the
        Third-Party Websites or the Third-Party Content. Inclusion of, linking
        to, or permitting the use or installation of any Third-Party Websites or
        any Third-Party Content does not imply approval or endorsement thereof
        by us. If you decide to leave the Bot and access the Third-Party
        Websites or to use or install any Third-Party Content, you do so at your
        own risk, and you should be aware these Terms of Use no longer govern.
        You should review the applicable terms and policies, including privacy
        and data gathering practices, of any website to which you navigate from
        the Bot or relating to any applications you use or install from the Bot.
        Any purchases you make through Third-Party Websites will be through
        other websites and from other companies, and we take no responsibility
        whatsoever in relation to such purchases which are exclusively between
        you and the applicable third party. You agree and acknowledge that we do
        not endorse the products or services offered on Third-Party Websites and
        you shall hold us harmless from any harm caused by your purchase of such
        products or services. Additionally, you shall hold us harmless from any
        losses sustained by you or harm caused to you relating to or resulting
        in any way from any Third-Party Content or any contact with Third-Party
        Websites.
        <div className="text-3xl text-indigo-200 my-2">Bot Management</div>
        We reserve the right, but not the obligation, to: (1) monitor the Bot
        for violations of these Terms of Use; (2) take appropriate legal action
        against anyone who, in our sole discretion, violates the law or these
        Terms of Use, including without limitation, reporting such user to law
        enforcement authorities; (3) in our sole discretion and without
        limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Bot or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Bot in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Bot.
        <div className="text-3xl text-indigo-200 my-2">Privacy Policy</div>
        We care about data privacy and security. By using the Bot, you agree to
        be bound by our Privacy Policy, which is incorporated into these Terms
        of Use. Please be advised the Bot is hosted in Germany. If you access
        the Bot from any other region of the world with laws or other
        requirements governing personal data collection, use, or disclosure that
        differ from applicable laws in Germany, then through your continued use
        of the Bot, you are transferring your data to Germany, and you agree to
        have your data transferred to and processed in Germany. Further, we do
        not knowingly accept, request, or solicit information from children or
        knowingly market to children. Therefore, in accordance with the U.S.
        Children’s Online Privacy Protection Act, if we receive actual knowledge
        that anyone under the age of 13 has provided personal information to us
        without the requisite and verifiable parental consent, we will delete
        that information from the Bot as quickly as is reasonably practical.
        <div className="text-3xl text-indigo-200 my-2">
          Term and Termination
        </div>
        These Terms of Use shall remain in full force and effect while you use
        the Bot. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE BOT (INCLUDING BLOCKING CERTAIN
        IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
        WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
        COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
        DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
        WARNING, IN OUR SOLE DISCRETION. If we terminate or suspend your access
        for any reason, you are prohibited from using a new account under your
        name, a fake or borrowed name, or the name of any third party, even if
        you may be acting on behalf of the third party. In addition to
        terminating or suspending your account, we reserve the right to take
        appropriate legal action, including without limitation pursuing civil,
        criminal, and injunctive redress.
        <div className="text-3xl text-indigo-200 my-2">
          Modifications and Interruptions
        </div>
        We reserve the right to change, modify, or remove the contents of the
        bot at any time or for any reason at our sole discretion without notice.
        However, we have no obligation to update any information on our bot. We
        also reserve the right to modify or discontinue all or part of the bot
        without notice at any time. We will not be liable to you or any third
        party for any modification, price change, suspension, or discontinuance
        of the bot. We cannot guarantee the bot will be available at all times.
        We may experience hardware, software, or other problems or need to
        perform maintenance related to the bot, resulting in interruptions,
        delays, or errors. We reserve the right to change, revise, update,
        suspend, discontinue, or otherwise modify the bot at any time or for any
        reason without notice to you. You agree that we have no liability
        whatsoever for any loss, damage, or inconvenience caused by your
        inability to access or use the bot during any downtime or discontinuance
        of the bot. Nothing in these Terms of Use will be construed to obligate
        us to maintain and support the bot or to supply any corrections,
        updates, or releases in connection therewith.
        <div className="text-3xl text-indigo-200 my-2">Governing Law</div>
        These conditions are governed by and interpreted following the laws of
        Germany, and the use of the United Nations Convention of Contracts for
        the International Sale of Goods is expressly excluded. If your habitual
        residence is in the EU, and you are a consumer, you additionally possess
        the protection provided to you by obligatory provisions of the law of
        your country of residence. Leonard Blechschmidt and yourself both agree
        to submit to the non-exclusive jurisdiction of the courts of Hesse,
        which means that you may make a claim to defend your consumer protection
        rights in regards to these Conditions of Use in Germany, or in the EU
        country in which you reside.
        <div className="text-3xl text-indigo-200 my-2">Corrections</div>
        There may be information on the bot that contains typographical errors,
        inaccuracies, or omissions, including descriptions, availability, and
        various other information. We reserve the right to correct any errors,
        inaccuracies, or omissions and to change or update the information on
        the bot at any time, without prior notice.
        <div className="text-3xl text-indigo-200 my-2">Disclaimer</div>
        THE BOT IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
        YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
        FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE BOT AND YOUR USE THEREOF, INCLUDING,
        WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE BOT’S CONTENT
        OR THE CONTENT OF ANY WEBSITES LINKED TO THE BOT AND WE WILL ASSUME NO
        LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
        OF THE BOT, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
        STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
        FROM THE WEBSITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
        WHICH MAY BE TRANSMITTED TO OR THROUGH THE BOT BY ANY THIRD PARTY,
        AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
        ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
        CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE BOT. WE
        DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
        PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
        BOT, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
        FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY
        TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
        YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
        PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
        ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
        WHERE APPROPRIATE.
        <div className="text-3xl text-indigo-200 my-2">
          Limitations of Liability
        </div>
        IN NO EVENT WILL ME BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
        DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
        DAMAGES ARISING FROM YOUR USE OF THE BOT, EVEN IF WE HAVE BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
        CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER
        AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
        TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND
        INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
        EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
        SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
        YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        <div className="text-3xl text-indigo-200 my-2">Indemnification</div>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) use of the Bot;
        (2) breach of these Terms of Use; (3) any breach of your representations
        and warranties set forth in these Terms of Use; (4) your violation of
        the rights of a third party, including but not limited to intellectual
        property rights; or (5) any overt harmful act toward any other user of
        the Bot with whom you connected via the Bot. Notwithstanding the
        foregoing, we reserve the right, at your expense, to assume the
        exclusive defense and control of any matter for which you are required
        to indemnify us, and you agree to cooperate, at your expense, with our
        defense of such claims. We will use reasonable efforts to notify you of
        any such claim, action, or proceeding which is subject to this
        indemnification upon becoming aware of it.
        <div className="text-3xl text-indigo-200 my-2">User Data</div> We will
        maintain certain data that you transmit to the Bot for the purpose of
        managing the performance of the Bot, as well as data relating to your
        use of the Bot. Although we perform regular routine backups of data, you
        are solely responsible for all data that you transmit or that relates to
        any activity you have undertaken using the Bot. You agree that we shall
        have no liability to you for any loss or corruption of any such data,
        and you hereby waive any right of action against us arising from any
        such loss or corruption of such data.
        <div className="text-3xl text-indigo-200 my-2">
          Electric Communications, Transactions and Signatures
        </div>
        Using the Bot, visiting the website, sending us emails, and completing
        online forms constitute electronic communications. You consent to
        receive electronic communications, and you agree that all agreements,
        notices, disclosures, and other communications we provide to you
        electronically, via email and on the Bot, satisfy any legal requirement
        that such communication be in writing. YOU HEREBY AGREE TO THE USE OF
        ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
        ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
        INITIATED OR COMPLETED BY US OR VIA THE BOT. You hereby waive any rights
        or requirements under any statutes, regulations, rules, ordinances, or
        other laws in any jurisdiction which require an original signature or
        delivery or retention of non-electronic records, or to payments or the
        granting of credits by any means other than electronic means.
        <div className="text-3xl text-indigo-200 my-2">Miscellaneous</div>
        These Terms of Use and any policies or operating rules posted by us on
        the website or in respect to the Bot constitute the entire agreement and
        understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Terms of Use shall not operate as a waiver
        of such right or provision. These Terms of Use operate to the fullest
        extent permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control. If any provision or part of a provision
        of these Terms of Use is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed
        severable from these Terms of Use and does not affect the validity and
        enforceability of any remaining provisions. There is no joint venture,
        partnership, employment or agency relationship created between you and
        us as a result of these Terms of Use or use of the Bot. You agree that
        these Terms of Use will not be construed against us by virtue of having
        drafted them. You hereby waive any and all defenses you may have based
        on the electronic form of these Terms of Use and the lack of signing by
        the parties hereto to execute these Terms of Use.
      </div>
    </div>
  );
};

export default Terms;
