const Home = () => {
  return (
    <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
      <div className="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
        <span className="text-7xl font-bold uppercase text-yellow-400">
          Celia
        </span>
        <h6 className="font-bold text-xl sm:text-xl text-white leading-tight mt-4">
          Celestial World Status & Auto Ban Bot
        </h6>
        <a
          href="https://discord.com/api/oauth2/authorize?client_id=821296084222869515&permissions=2048&scope=bot"
          target="_blank"
          rel="noreferrer"
          className="block bg-white hover:bg-gray-100 py-3 px-4 rounded-lg text-lg text-gray-800 font-bold uppercase mt-10"
        >
          Add to your server
        </a>
        <div className="bg-opacity-10 bg-black rounded-xl p-3">
          <div className="font-bold text-white mt-4 ml-2 text-sm">Notes :</div>
          <div className="font-bold text-white mt-1 ml-2 text-sm">
            • If you want to use "Celestial World Status" feature, create a
            channel name status and bot is ready to use.
          </div>
          <div className="font-bold text-white mt-1 ml-2 text-sm">
            • If you want a notification when one island core down just create
            ISLAND_PLAYER role and give to users.
          </div>
          <div className="font-bold text-white mt-1 ml-2 text-sm">
            • If you want notification when one frosthain core down just create
            FROST_PLAYER role and give to users.
          </div>
          <div className="font-bold text-white mt-1 ml-2 text-sm">
            • If you want ban a user after left from your server, give ban
            members permission to bot. But you need to give "Ban Members"
            permission to bot.
          </div>
          <div className="font-bold text-white mt-1 ml-2 text-sm">
            • If you want to get join/left/ban logs, create join-logs channel
            for joins, create ban-logs channel for left/bans.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
