import github from "./github.svg";
const Contact = () => {
  return (
    <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
      <div className="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
        <span className="text-7xl font-bold text-yellow-400">xNoJustice</span>
        <h6 className="font-bold text-xl sm:text-xl text-white leading-tight mt-4">
          Jr. Web Developer
        </h6>
        <a
          href="https://github.com/xNoJustice"
          target="_blank"
          rel="noreferrer"
          className="flex justify-center items-center bg-white hover:bg-gray-100 py-3 px-4 rounded-lg text-lg text-gray-800 font-bold uppercase mt-10"
        >
          <img src={github} alt="github" className="mr-1 text-center" />
          <span>Github</span>
        </a>
      </div>
    </div>
  );
};

export default Contact;
